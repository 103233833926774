import React from "react"
import PropTypes from "prop-types"
import Button from "@components/Button/Button"
import NuvoImage from "../../components/NuvoImage"
import SocialReviews from "../../components/SocialReviews/SocialReviews"
import ContentColumn from "./ContentColumn"

function TopSection(props) {
  const { backUrl, doctor, language, post } = props
  const { hasProfileImage, cutoutImage, heading, topBlurb, badge } = post
  if (hasProfileImage) {
    return (
      <div className="columns is-desktop top-section doctor">
        <Button
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={language === "es" ? "/es/acerca-de-nosotros/" : backUrl}
        />

        <div className="column is-1" />
        <div className="column doctor-image-wrapper is-11">
          <NuvoImage
            cloudName="nuvolum"
            publicId={cutoutImage}
            useAR
            width="auto"
          />
        </div>
        <div className="column is-1" />

        <ContentColumn
          heading={heading}
          topBlurb={topBlurb}
          badge={badge}
          language={language}
          doctor={doctor}
        />
        <div className="column" />
      </div>
    )
  }
  return (
    <>
      <div className="columns has-text-centered top-section doctor imageless is-vcentered">
        <Button
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={language === "es" ? "/es/acerca-de-nosotros/" : backUrl}
        />

        <div className="column is-3" />
        <div className="column">
          <h1>{heading}</h1>
          <h3 className="doctor-top-blurb-subheading">{topBlurb}</h3>
          <div className={`rating-review ${!badge.hasBadge ? "no-badge" : ""}`}>
            {badge && badge.hasBadge && (
              <NuvoImage className="badge" publicId={badge.badgeImage} />
            )}

            {doctor.rating && (
              <SocialReviews
                socialData={doctor}
                google
                isCentered
                language={language}
              />
            )}
          </div>
        </div>
        <div className="column is-3" />
      </div>
      {/* <div className="columns" style={{ marginTop: "44px" }}>
        <div className="column is-offset-2 is-20">
          <div className="hl"></div>
        </div>
      </div> */}
    </>
  )
}

TopSection.propTypes = {
  post: PropTypes.object.isRequired,
  backUrl: PropTypes.string,
  doctor: PropTypes.object,
  language: PropTypes.string
}

export default TopSection
