// See https://marked.js.org/#/USING_ADVANCED.md#options for full list of setOptions

import React from "react";
import PropTypes from "prop-types";

var classNames = require("classnames");
var marked = require('marked');
marked.setOptions({
    renderer: new marked.Renderer(),
    smartLists: true
});

function createHtml(html) {
    return { __html: html }
}

function MarkdownViewer({ markdown, replaceSymbols = true, priority, className, style }) {


    // For <h> tags
    if (priority) {
        const CustomTag = `h${priority}`;
        return (
            <CustomTag className={className}>{markdown}</CustomTag>
        )
    }

    var viewerClasses = classNames("marked", className, {})

    if (!markdown) {
        console.warn(`No 'markdown' received by MarkdownViewer.`);
        return (null)
    }

    return (
        <div
            style={style}
            className={viewerClasses}
            dangerouslySetInnerHTML={createHtml(
                marked(
                    replaceSymbols ?
                        removeSymbols(markdown)
                        :
                        markdown
                )
            )}
        ></div>
    )
}

MarkdownViewer.propTypes = {
    className: PropTypes.string,
    markdown: PropTypes.string.isRequired,
    replaceSymbols: PropTypes.bool,
    priority: PropTypes.string,
    style: PropTypes.object,
}

export default MarkdownViewer

// Helper functions

function removeSymbols(str) {
    str = str.replace(/[™®©]/g, '<sup>$&</sup>');
    let title = str.match(/title='(.*?)'/ig);
    if (title) {
        let newStr = str;
        for (let i = 0; i < title.length; i++) {
            let newTitle = title[i].replace("<sup>", "").replace("</sup>", "");
            newStr = newStr.replace(title[i], newTitle);
        }
        return newStr
    }
    else {
        return str;
    }
}